import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { RegisterPage } from '../register/register.page';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { ForgottenpswdPage } from '../forgottenpswd/forgottenpswd.page';

@Component({
	selector: 'app-login',
	templateUrl: './login.page.html',
	styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
	constructor(
		private modalController: ModalController,
		private authService: AuthService,
		private navCtrl: NavController,
		private alertService: AlertService
	) {}

	ngOnInit() {}

	// Dismiss Login Modal
	dismissLogin() {
		this.modalController.dismiss();
	}

	// On Register button tap, dismiss login modal and open register modal
	async registerModal() {
		this.dismissLogin();
		const registerModal = await this.modalController.create({
			component: RegisterPage,
		});
		return await registerModal.present();
	}

	login(form: NgForm) {
		this.authService.login(form.value.email, form.value.password).then(
			(data) => {
				this.alertService.presentToast('Logged In');
				this.dismissLogin();
				this.navCtrl.navigateRoot('/tabs/home');
			},
			(error) => {
				console.log(error);
			},
		);
	}

	async presentModal(idx) {
		this.dismissLogin();
		const modal = await this.modalController.create({
			component: ForgottenpswdPage,
			componentProps: {},
		});
		return await modal.present();
	}

	dismissModal() {
		// using the injected ModalController this page
		// can "dismiss" itself and optionally pass back data
		this.modalController.dismiss({
			dismissed: true,
		});
	}
}
